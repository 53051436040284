import myAxios from "../apis/axios"

const defaultOptions={
    name: JSON.parse(localStorage.userInfo).name,
    token: JSON.parse(localStorage.userInfo).token,
  }

  export default async  function getTable(api,resOptions){
    const options={
        ...resOptions,...defaultOptions
    }
   let result= await  myAxios({
        method: options.method||'post',
        url: api,
        data: options
    })
    return result
  }