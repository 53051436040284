<template>
  <div>
    <div class="top">
      <Hform
        v-if="allProps.isShowBtn.searchBtn"
        :allProps="allProps"
        :formType="searchFormType"
        @searchData="searchData"
      />
      <el-button
        class="addBtn"
        type="primary"
        @click.native.prevent="dialogForm('新增')"
        v-if="allProps.isShowBtn.addBtn"
      >
        新增
      </el-button>

    </div>

    <el-table :data="tableData" style="width: 100%">
      <template v-if="allProps.type">
        <el-table-column
          v-for="type in allProps.type"
          :key="type"
          :type="type"
          width="100"
          fixed
        />
      </template>
      <template v-for="item in allProps.items">
        <el-table-column
          v-if="item.type ? item.type.includes('table') : false"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :key="item.prop"
          :fixed="item.prop == 'operation' ? 'right' : false"
          show-overflow-tooltip
        >
          <!-- :data="scope.row"获取当前行数据用data传xx.data接收 -->
          <template slot-scope="scope">
            <slot
              v-if="item.Ttype == 'slot'"
              :name="item.prop"
              :data="scope.row"
              :index="scope.$index"
            />
            <component
              v-else
              :is="!item.Ttype ? 'con-text' : `con-${item.Ttype}`"
              :data="scope.row"
              :column="item"
            >
            </component>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageParams.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageParams.pageSize"
      :total="pageParams.total"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <el-dialog
      :title="allProps.dialog.title"
      :visible.sync="allProps.dialog.isShow"
      :close-on-click-modal="false"
      :width="allProps.dialog.width || '30%'"
      @close="resetForm(submitFormType.refName)"
    >
      <Hform :allProps="allProps" :formType="submitFormType" ref="rule">
        <template v-for="item in formProps" v-slot:[item]="scope">
          <slot :name="item" :data="scope.data" />
        </template>
      </Hform>
    </el-dialog>
  </div>
</template>
<script>
const modules = {};
const files = require.context("@/components/control", true, /\.vue$/);
files.keys().forEach((item) => {
  const key = item.split("/");
  const name = key[1];
  const component = files(item).default;
  modules[`con-${name}`] = component;
});
import { getTable } from "@/hooks";
export default {
  name: "Htable",
  components: {
    ...modules,
    Hform: () => import("@/components/Form/Form.vue"),
    // DownloadExcel: JsonExcel,
  },
  props: {
    allProps: {
      type: Object,
      default: () => ({}),
    },
  },
  provide() {
    return {
      getTableList: this.getTableList,
    };
  },
  data() {
    return {
      searchFormType: {
        type: "search",
        value: 0,
        refName: "submitForm",
        data: {},
        excelData: [],
        labelWidth: "auto",
      },
      submitFormType: {
        type: "edit",
        value: 1,
        refName: "ruleForm",
        data: {},
        labelWidth: 0,
      },
      pageParams: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      formProps: [],
      // excelData: [],
      initData: {},
    };
  },
  created() {
    this.allProps?.getMethods?.initTable === "false" ? "" : this.getTableList();
    this.submitFormType.labelWidth = this.allProps.labelWidth ?? "auto";
    this.allProps.items;
    this.allProps.items.forEach((item) => {
      if (item.Ftype === "slot") this.formProps.push(item.prop);
    });
    this.initData = this.allProps.data;
  },
  methods: {
    async searchData(val) {
      this.searchFormType.data = val;
      console.log("sear");
      await this.getTableList(val.type);
    },
    dialogForm(title, row = {}) {
      this.allProps.isShowBtn.dialogFormBtn = true;
      this.submitFormType.type = "add";
      this.submitFormType.data = { ...this.initData };

      console.log(this.submitFormType.data, "open");
      this.allProps.dialog = {
        isShow: true,
        title,
      };
    },
    async getTableList(data) {
      let pageParams = { ...this.pageParams };
      if (data?.type === "excel"){ pageParams.pageSize = 200;}else{
        pageParams.pageSize = 10;
      }
      let api = this.$parent?.changeApi?.(this.searchFormType.data, pageParams);
      const res = await getTable(api ?? this.allProps.api.get, {
        ...this.pageParams,
        ...this.searchFormType.data,
        ...this.allProps.getMethods,
      });
      
      // if(data.type === "excel")return
      if (res) {
        let newdata = this.$parent?.changeTableList?.(res.data.data);
        let resultData = (newdata?.length) ? newdata : res.data.data;
        if (data?.type === "excel") {
          return [...resultData];
        } else {
          this.tableData = resultData;
          this.pageParams.total = res.data.TotalCount;
        }
      }
    },
    changeTableByKey(key, value, index) {
      if (typeof index !== "number")
        this.tableData.forEach((v) => (v[key] = value));
      else this.tableData[index][key] = value;
    },
    handleSizeChange(e) {
      this.pageParams.pageSize = e;
    },
    handleCurrentChange(e) {
      this.pageParams.page = e;
    },
    resetForm(formName) {
      this.$refs.rule.resetForm(formName);
    },
  },
  watch: {
    pageParams: {
      handler() {
        this.getTableList();
      },
      deep: true,
    },
    "allProps.dialog.title": {
      handler(newVal, oldVal) {
       
        if (newVal === "新增") this.submitFormType.type = "add";
        else {this.submitFormType.type = "edit" 
        this.submitFormType.data = this.allProps.data;};
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog .btnForm {
  width: 100%;
  display: flex;
  justify-content: end;
}
.top {
  margin-bottom: 20px;
  // border: 1px solid #111;
}
</style>
