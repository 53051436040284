<template>
  <div>
    <!-- <img :src="data.img" /> -->
    <el-image
    v-if="data.img"
      :style="data.style||'width: 260px'"
      :src="$mainUrl(data.img)"
      :preview-src-list="[data.img]"
    >
    </el-image>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped lang="less"></style>
