<template>
  <div>
    <div> {{data[column.prop]}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  props:{
      data: {
        type: Object,
        default: () => {},
      },
      column: {
        type: Object,
        default: () => {},
      },
  }
};
</script>
<style scoped lang="less"></style>
